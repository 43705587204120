import React from "react";
import { Wrapper, Box, Title, TextContent, Action, WrapButton } from "./styles";
import { FaWhatsapp } from "react-icons/fa";
import arrow from "./arrow.svg";
import Button from "components/Button";
import { shareWhatsapp } from "helpers/share-helpers";
import {WHATSAPP_NUMBER_FORMATTED} from "../../../constants";

const CtaSchedule = () => {
  const handleClickChat = (e) => {
    try {
      if (window.Tawk_API) {
        window.Tawk_API.maximize();
      }
      e.preventDefault();
    } catch {
      console.error('Erro ao abrir chat')
    }
  };

  return (
    <Wrapper>
      <Box>
        <TextContent>
          <Title>
            Quer saber mais? <br />
            Converse com um dos nossos consultores!
          </Title>
        </TextContent>
        <Action>
          <WrapButton>
            <Button
              as="a"
              href="https://youtu.be/6yD1KbNfACo"
              target="_blank"
              rel="noopener noreferrer"
              color="tertiary"
              kind="solid"
            >
              Ver uma demonstração
            </Button>
            <Button
              as="a"
              href={shareWhatsapp(WHATSAPP_NUMBER_FORMATTED)}
              target="_blank"
              rel="noreferrer noopener"
              color="whatsapp"
              kind="solid"
              // onClick={handleClickChat}
            >
              <FaWhatsapp /> Conversar com consultor
              <img src={arrow} alt="Arrow" />
            </Button>
          </WrapButton>
        </Action>
      </Box>
    </Wrapper>
  );
};

export default CtaSchedule;
